<script setup>
import { faArrowUpRightDots, faCircle, faTractor } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  subtitle: {
    type: String,
    required: true
  },
  isNew: {
    type: Boolean,
    default: false
  },
  icon: {
    type: Object,
    required: true
  },
  backgroundImage: {
    type: String,
    default: "url('/assets/images/leaves3.png')"
  },
  linkName: {
    type: String,
    required: true
  },
  linkText: {
    type: String,
    default: 'Log figures'
  }
})
</script>

<template>
  <Card style="overflow: hidden">
    <template #header>
      <div
        class="w-full card-header flex justify-center items-center bg-cover"
        :style="{ backgroundImage: props.backgroundImage }"
      >
        <div class="fa-stack fa-4x">
          <font-awesome-icon
            :icon="faCircle"
            class="fa-stack-2x icon-background text-white opacity-95"
          />
          <font-awesome-icon :icon="icon" class="fa-stack-1x" />
        </div>
      </div>
    </template>
    <template #content>
      <Tag v-if="isNew" value="New Survey"></Tag>
      <div class="text-2xl mt-4 text-color font-semibold">{{ title }}</div>
      <div class="mt-2">{{ subtitle }}</div>
    </template>
    <template #footer>
      <Button
        outlined
        severity="secondary"
        class="text-color"
        as="router-link"
        :to="{ name: linkName }"
      >
        {{ linkText }}
        <font-awesome-icon :icon="faArrowUpRightDots" />
      </Button>
    </template>
  </Card>
</template>

<style scoped>
.card-header {
  min-height: 168px;
}
</style>