import { createRouter, createWebHistory } from 'vue-router'
import MainPage from '@/pages/MainPage.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import { reportType } from '@/enums/reportType'
import { permissionType } from '@/enums/permissionType.js'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { useApplicationService } from '@/services/applicationService.js'
import { useApplicationStore } from '@/stores/applicationStore.js'
import HelpPage from '@/pages/HelpPage.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: MainLayout,
      beforeEnter: async (to, from) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      },
      children: [
        {
          path: '',
          name: 'dashboard',
          component: MainPage,
          meta: {
            title: 'Home',
            icon: 'home.svg'
          }
        },
        {
          path: 'sales',
          name: 'sales',
          children: [
            {
              path: 'retail',
              name: 'retail',
              children: [
                {
                  path: 'monthly',
                  name: 'retail.monthly',
                  meta: {
                    title: 'Retail Sales',
                    icon: 'sales.svg',
                    reportTemplateType: reportType.RETAIL_SALES_MONTHLY
                  },
                  children: [
                    {
                      path: 'report',
                      name: 'sales.retail.monthly.report',
                      component: () => import('../pages/sales/ReportPage.vue')
                    },
                    {
                      path: 'picker',
                      name: 'sales.retail.monthly.picker',
                      component: () => import('../pages/sales/PickerPage.vue')
                    },
                    {
                      path: 'log/:id(\\d+)?',
                      props: true,
                      name: 'sales.retail.monthly.log',
                      component: () => import('../pages/sales/LogPage.vue'),
                      meta: {
                        hideHeader: true
                      }
                    }
                  ]
                },
                {
                  path: 'weekly',
                  name: 'weekly',
                  meta: {
                    title: 'Retail Weekly Sales',
                    icon: 'sales.svg',
                    reportTemplateType: reportType.RETAIL_SALES_WEEKLY
                  },
                  children: [
                    {
                      path: 'report',
                      name: 'sales.retail.weekly.report',
                      component: () => import('../pages/sales/ReportPage.vue')
                    },
                    {
                      path: 'picker',
                      name: 'sales.retail.weekly.picker',
                      component: () => import('../pages/sales/PickerPage.vue')
                    },
                    {
                      path: 'log/:id(\\d+)?',
                      props: true,
                      name: 'sales.retail.weekly.log',
                      component: () => import('../pages/sales/LogPage.vue'),
                      meta: {
                        hideHeader: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'grower',
              name: 'grower',
              children: [
                {
                  path: 'monthly',
                  name: 'monthly',
                  meta: {
                    title: 'Grower Sales',
                    icon: 'sales.svg',
                    reportTemplateType: reportType.GROWER_SALES
                  },
                  children: [
                    {
                      path: 'report',
                      name: 'sales.grower.monthly.report',
                      component: () => import('../pages/grower-sales/ReportPage.vue')
                    },
                    {
                      path: 'picker',
                      name: 'sales.grower.monthly.picker',
                      component: () => import('../pages/grower-sales/PickerPage.vue')
                    },
                    {
                      path: 'log/:id(\\d+)?',
                      props: true,
                      name: 'sales.grower.monthly.log',
                      component: () => import('../pages/grower-sales/LogPage.vue'),
                      meta: {
                        hideHeader: true
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'help',
          name: 'help',
          component: HelpPage,
          meta: {
            title: 'Need Help?',
          }
        },
        {
          path: 'account',
          name: 'account',
          meta: {
            permission: permissionType.ADMIN
          },
          children: [
            {
              path: 'organisation',
              name: 'account.organisation',
              component: () => import('../pages/account/OrganizationPage.vue'),
              meta: {
                title: 'Organisation',
                icon: faUsers,
                reportTemplateType: reportType.GROWER_SALES
              }
            }
          ]
        }
      ]
    },
    {
      children: [
        {
          path: '/onboarding',
          name: 'onboarding',
          component: () => import('../pages/onboarding/OnboardingPage.vue')
        },
        {
          path: '/onboarding/:externalId',
          name: 'onboarding2',
          component: () => import('../pages/onboarding/OnboardingPage.vue')
        }
      ],
      beforeEnter: async (to, from) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../pages/LoginPage.vue'),
      beforeEnter: async (to) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      }
    },
    {
      path: '/no-access',
      name: 'noAccess',
      component: () => import('../pages/NoAccessPage.vue'),
      beforeEnter: async (to) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      }
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('../pages/NoAccessPage.vue'),
      beforeEnter: async (to) => {
        const applicationService = useApplicationService()
        return applicationService.updateAuthData(to)
      },
      meta: {
        message: 'You don\'t currently have acccess to HTA Member Benchmarking, please contact marketinfo@hta.org.uk for assistance.'
      }
    }
  ]
})

export default router
