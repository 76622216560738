<script setup>
import { useUserStore } from '@/stores/userStore.js'
import { computed } from 'vue'
import BusinessReport from '@/pages/sales/sections/BusinessReport.vue'
import { useAccountStore } from '@/stores/accountStore.js'
import NotificationList from '@/components/dashboard/notifications/NotificationList.vue'
import HomeWidget from '@/components/dashboard/widgets/homeWidget.vue'
import { usePermissionService } from '@/services/permissionService'
import { faBuildingWheat, faTractor, faWheatAwn } from '@fortawesome/free-solid-svg-icons'
import { reportType } from '@/enums/reportType'

const userStore = useUserStore()
const accountStore = useAccountStore()
const permissionService = usePermissionService()

const isGrower = computed(() => {
  return accountStore.permissions.reports.GROWER_SALES
})

const userInitials = computed(() => {
  return userStore.user.first_name[0] + userStore.user.last_name[0]
})
</script>

<template>
  <div class="w-full justify-center flex py-6 bg-surface-50 border-b">
    <div class="text-2xl w-10/12 font-semibold flex align-middle">
      <Avatar
        class="mr-4"
        :label="userInitials"
        shape="circle"
        style="background-color: #76ab16; color: #eeeeee"
      />
      Hey {{ userStore.user.first_name }}, welcome back
    </div>
  </div>
  <div class="w-full flex flex-wrap justify-center mb-32">
    <div class="w-10/12 mt-6 grid grid-cols-3 gap-4">
      <div class="col-span-3 text-xl">What's new</div>
      <div class="col-span-3">
        <notification-list />
      </div>
      <!--      <grower-widget />-->
      <home-widget
        v-if="permissionService.userCan(reportType.RETAIL_SALES_MONTHLY)"
        title="Retail Sales"
        link-name="sales.retail.monthly.report"
        :icon="faWheatAwn"
        subtitle="This report typically includes information such as total sales revenue, number of transactions, average transaction value, and comparisons to previous months or years."
        background-image="url('/assets/images/leaves1.png')"
        is-new
      />
      <home-widget
        v-if="permissionService.userCan(reportType.RETAIL_SALES_WEEKLY)"
        title="RDF Weekly Retail Sales"
        link-name="sales.retail.weekly.report"
        :icon="faBuildingWheat"
        subtitle="This report usually includes data such as total sales revenue, number of transactions, average transaction value, and comparisons to previous weeks."
        background-image="url('/assets/images/leaves2.png')"
        is-new
      />
      <home-widget
        v-if="permissionService.userCan(reportType.GROWER_SALES)"
        title="Grower Sales"
        link-name="sales.grower.monthly.report"
        :icon="faTractor"
        subtitle="This report typically includes information such as total sales revenue, quantity of produce sold, average selling price, and comparisons to previous periods."
        is-new
      />
    </div>
  </div>
</template>

<style scoped></style>
