<script setup>
import { useAxios } from '@/composables/axios.js'
import { computed, onMounted, ref } from 'vue'
import { useAccountStore } from '@/stores/accountStore.js'
import Notification from '@/components/dashboard/notifications/NotificationComponent .vue'

const axios = useAxios()
const accountStore = useAccountStore()


const notifications = ref({})

const currentAccount = computed(() => {
  return accountStore.currentAccount
})

onMounted(async () => {
  const {data} = await axios.get(`/accounts/${currentAccount.value.id}/notifications`)
  notifications.value = data
})
</script>

<template>
<notification v-for="notification in notifications" :key="notification.id" :notification="notification" />
</template>

<style scoped>

</style>