<script setup></script>

<template>
  <div class="w-full flex flex-wrap justify-center mb-32">
    <div class="w-10/12 mt-6 grid grid-cols-1 gap-4">
        <p>Need help with HTA Member Benchmarking? Please contact <a href="mailto:marketinfo@hta.org.uk">marketinfo@hta.org.uk</a> for assistance.</p>
        <p class="font-bold">Logged in for the first time and can't see anything?</p>
        <p>You'll need the Benchmarking 'Admin' user at your business to grant you access permissions for the different benchmarking studies available. They can login and manage your permissions via the 'Organisation' page.</p>
        <p class="font-bold">Admin user for your business left the business?</p>
        <p>Email <a href="mailto:marketinfo@hta.org.uk">marketinfo@hta.org.uk</a> and we can update this for you.</p>
    </div>
  </div>
</template>

<style scoped></style>
