import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'
import { useUserStore } from '@/stores/userStore.js'
import { useToast } from 'primevue/usetoast'

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_API_URL
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    // error.response.
    const authStore = useAuthStore()
  
    if ([401].includes(error.response?.status)) {
      await authStore.logout('/forbidden')
    }

    if ([403].includes(error.response?.status)) {
      await authStore.logout('/no-access')
    }
    return Promise.reject(error)
  }
)

export const useAxios = () => axiosInstance
